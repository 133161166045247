<template>
    <section>
        <pageLoader v-if="loadershow" :loadingText="'Traitement des données en cours'"/>
        <div class="container-scroller">
            <div class="container-fluid page-body-wrapper full-page-wrapper">
                <div class="content-wrapper d-flex align-items-center auth">
                    <div class="row w-100 flex-grow">
                        <div class="col-xl-4 col-lg-6 mx-auto">
                            <div class="auth-form-light text-left p-5 my_auth">
                            <div class="brand-logo">
                                <img class="logo_image" src="@/assets/images/import/logo-mini.png">
                            </div>
                            <h4>Changement de mot de passe!</h4>
                            <h6 class="font-weight-light">Un code sera envoyé à votre adresse email</h6>
                            <!-- formulaire d'authentification -->
                            <form class="pt-3" @submit.prevent="submit()">
                                <div class="form-group">
                                <input type="text" class="form-control form-control-lg" v-model="email" placeholder="Adresse email">
                                </div>
                                <div class="mt-3">
                                <button type="submit " class="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn btn-sign-up">ENVOYER</button>
                                </div>
                            </form>
                            <!-- Fin formulaire -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios'
import pageLoader from '../components/pageLoader.vue'
export default {
    name:"forgot-password",
    components:{
        pageLoader
    },
    data () {
        return {
            email: "",
            loadershow:false
        }
    },
    methods: {
        submit() {
            this.loadershow=true
            axios.post("changepasswd", {
                email: this.email,
                link_code_page: "https://monechappeebelle.fr/nouveaumotdepasse/",
                unuscribe_url: "https://monechappebelle.fr/unuscribe"
            })
            .then(resSubmit => {
                alert('Un email vous sera envoyé d\'ici 5mn à 10mn')
                resSubmit
                // console.log({resSubmit})
                // this.$router.push({
                //     name:"newPassword"
                // })
            })
            .catch(errSubmit => {
                this.loadershow=false
                alert('Une erreur s\'est produite, Merci de réessayer')
                console.log(errSubmit)
                })
        }
    }
}
</script>